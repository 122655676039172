"use client";
import type React from "react";
import Image from "next/image";
import Uow_Seal from "@/public/images/uow_seal.webp";
import Psm_Seal from "@/public/images/psm-seal.webp";
import Nymc_Seal from "@/public/images/nymc-seal.webp";

import { motion } from "framer-motion";
const testimonialList = [
  {
    img: Uow_Seal,
    name: "Shafaq",
    position: "University of Washington (Premed)",
    content:
      '"There is so much depth I need to learn and finding a platform that can guide me through my MCAT study helps me stay organized and optimize the time I spend studying outside the classroom."',
  },
  {
    img: Psm_Seal,
    name: "Braden",
    position: "Perelman School of Medicine at the University of Pennsylvania",
    content:
      '"The ability to upload multiple sources of content and group it together in one place and then create a study plan that’s personalized to me is exactly what I’ve been looking for."',
  },
  {
    img: Nymc_Seal,
    name: "Keelyn",
    position: "New York Medical College",
    content:
      '"My time is extremely valuable and so being able to upload material and identify my weak points and reinforce strong points before I get into the full out sprint leading up to exam week is a game changer."',
  },
];

const Testimonial: React.FC = () => {
  return (
    <section className="w-full">
      {/* One large glassmorphism card */}
      <motion.div
        className="p-6 bg-grey-800/10 backdrop-blur-lg rounded-xl shadow-lg border border-grey-600"
        initial={{ opacity: 0, y: 10 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {testimonialList.map((item, index) => (
            <motion.div
              className="text-center"
              key={item.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{
                duration: 0.6,
                ease: "easeOut",
                delay: index * 0.2,
              }}
            >
              {/* Quote */}
              <p className="relative p-6 text-md font-light italic leading-8 text-grey-100 md:text-md">
                {item.content}
              </p>

              {/* Student image and name */}
              <div className="flex flex-col items-center">
                <Image
                  alt={item.name}
                  className="w-16 h-16 md:w-20 md:h-20 mb-4 rounded-full shadow-md"
                  src={item.img}
                  width={80}
                  height={80}
                />
                <h4 className="mb-1 text-lg font-medium font-heading text-base-white">
                  {item.name}
                </h4>
                <p className="text-sm text-grey-300">{item.position}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default Testimonial;
