"use client";

import { cn } from "@/utils/cn";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { forwardRef, useRef } from "react";

const Slider = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  const inputRef = useRef(null);
  return (
    <SliderPrimitive.Root
      className={cn(
        "relative flex w-full touch-none select-none items-center pl-2",
        className,
      )}
      onValueChange={() => {}}
      ref={ref}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-grey-700">
        <SliderPrimitive.Range className="absolute h-full bg-grey-100" />
      </SliderPrimitive.Track>

      <SliderPrimitive.Thumb className="block size-4 rounded-full border-2 bg-blue-500 border-grey-200 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
