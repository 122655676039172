"use client";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type React from "react";
import { GlobalStoreProvider, useGlobalStore } from "./GlobalStoreProvider";
import { QueryProvider } from "./QueryProvider";
import {
  dehydrate,
  HydrationBoundary,
  useQueryClient,
} from "@tanstack/react-query";

export default function GlobalContextProvider({
  children,
}: React.PropsWithChildren) {
  return (
    <GlobalStoreProvider>
      <QueryProvider>{children}</QueryProvider>
    </GlobalStoreProvider>
  );
}
