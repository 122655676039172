"use client";

import { type GlobalStoreT, createGlobalStore } from "@/store/store";
import { createContext, useContext, useRef } from "react";
import { useStore } from "zustand";

export interface GlobalStoreProviderProps {
  readonly children: React.ReactNode;
}

type StoreContextValue = typeof createGlobalStore;

export const GlobalStoreContext = createContext<StoreContextValue | undefined>(
  undefined,
);

export function GlobalStoreProvider({ children }: GlobalStoreProviderProps) {
  const storeRef = useRef<StoreContextValue>();

  if (!storeRef.current) {
    storeRef.current = createGlobalStore;
  }

  return (
    <GlobalStoreContext.Provider value={storeRef.current}>
      {children}
    </GlobalStoreContext.Provider>
  );
}

/**
 * Hook to access the global store context.
 *
 * 1. If you want to access the store directly, use `useGlobalStore()`
 * 2. If you want to access a specific part of the store, use `useGlobalStore((s) => s.myState)`
 */
export function useGlobalStore<T>(selector: (store: GlobalStoreT) => T): T;
export function useGlobalStore<T>(selector: (store: GlobalStoreT) => T) {
  const globalStoreContext = useContext(GlobalStoreContext);

  if (!globalStoreContext) {
    throw new Error("useGlobalStore must be use within GlobalStoreProvider");
  }

  return useStore(globalStoreContext, selector);
}

type __WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

// function __createSelectors<S extends StoreApi<object>>(_store: S) {
//   const store = _store as WithSelectors<typeof _store>
//   store.use = {}
//   for (const k of Object.keys(store.getState())) {
//     store.use[k] = () => useStore(_store, s => s[k as keyof typeof s])
//   }

//   return store
// }
