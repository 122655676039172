import { QueryClientProvider } from "@tanstack/react-query";
import { useGlobalStore } from "@/provider/GlobalStoreProvider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
import React from "react";
export const QueryProvider = React.memo(function QueryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { queryClient } = useGlobalStore((s) => s.global);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
});
