"use client";

import { cn } from "@/utils/cn";
import type { SeparatorProps } from "@radix-ui/react-separator";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { forwardRef, type ForwardRefExoticComponent } from "react";

const Separator: ForwardRefExoticComponent<SeparatorProps> = forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = "horizontal", decorative = true, ...props },
    ref,
  ) => (
    <SeparatorPrimitive.Root
      className={cn(
        "shrink-0 bg-border",
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        className,
      )}
      decorative={decorative}
      orientation={orientation}
      ref={ref}
      {...props}
    />
  ),
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
