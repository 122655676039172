"use client";

import { cn } from "@/utils/cn";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

/**
 * Avatar component that displays a user's profile picture or initials.
 * @param {'circle' | 'square'} [variant='circle'] - The shape of the avatar.
 * @returns {JSX.Element} The Avatar component.
 */
const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> & {
    variant?: "circle" | "square";
  }
>(({ className, variant = "circle", ...props }, ref) => (
  <AvatarPrimitive.Root
    className={cn(
      "relative flex h-10 w-10 shrink-0 overflow-hidden",
      variant === "circle" ? "rounded-full" : "rounded-md",
      className,
    )}
    ref={ref}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

/**
 * AvatarImage component that displays the actual image for the avatar.
 * @param {'circle' | 'square'} [variant] - The shape of the avatar image.
 * @returns {JSX.Element} The AvatarImage component.
 */
const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> & {
    variant?: "circle" | "square";
  }
>(({ className, variant, ...props }, ref) => (
  <AvatarPrimitive.Image
    className={cn(
      "aspect-square h-full w-full",
      variant === "circle" ? "rounded-full" : "rounded-md",
      className,
    )}
    ref={ref}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

/**
 * AvatarFallback component that displays when the avatar image fails to load.
 * @param {'circle' | 'square'} [variant='circle'] - The shape of the fallback avatar.
 * @returns {JSX.Element} The AvatarFallback component.
 */
const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> & {
    variant?: "circle" | "square";
  }
>(({ className, variant = "circle", ...props }, ref) => (
  <AvatarPrimitive.Fallback
    className={cn(
      "flex h-full w-full items-center justify-center bg-muted",
      variant === "circle" ? "rounded-full" : "rounded-md",
      className,
    )}
    ref={ref}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
