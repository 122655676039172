import type { SessionResult } from "@/auth/sessions";
import { QueryClient } from "@tanstack/react-query";
import { enableMapSet } from "immer";
import type { SliceCreator } from "./store";
import { defaultShouldDehydrateQuery } from "@tanstack/react-query";
import type { Query } from "@tanstack/react-query";
import { validateRequest } from "@/auth/sessions";
import { isServer } from "@tanstack/react-query";
import { createTson, type TsonType } from "tupleson";
import { Temporal } from "@js-temporal/polyfill";
import type { QueryClientConfig } from "@tanstack/react-query";
import type { user } from "../../../sia-database/src/schema/user";
import type { queries } from "@testing-library/dom";
import type { defaultOptions } from "zod-to-json-schema";
import json from "superjson";
export interface GlobalStateT {
  session: SessionResult["session"];
  queryClient: QueryClient;
  user: SessionResult["user"];
  sessionExpiryTime: Date | undefined;
}

export interface GlobalActionsT {
  setSession: (data: {
    session: SessionResult["session"];
    user: SessionResult["user"];
  }) => Promise<void>;
  setSessionExpiryTime: (expiryTime: Date) => void;
  setQueryClient: (queryClient: QueryClient) => void;
}

export interface GlobalSliceT {
  global: GlobalStateT & GlobalActionsT;
}

const __DEFAULT_GLOBAL_STATE: GlobalStateT = {
  session: null,
  queryClient: new QueryClient(),
  user: null,
  sessionExpiryTime: undefined,
};

function initializeGlobalState(): GlobalStateT {
  enableMapSet();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
        experimental_prefetchInRender: true,
      },
      dehydrate: {
        serializeData: (data) => json.serialize(data),
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) ||
          query.state.status === "pending",
      },
      hydrate: {
        deserializeData: (data) =>
          json.deserialize<{ json: unknown; meta: unknown }>(data),
      },
    },
  });

  return {
    session: null,
    queryClient,
    user: null,
    sessionExpiryTime: undefined,
  };
}

export const createGlobalSlice: SliceCreator<keyof GlobalSliceT> = (
  set,
  get,
) => ({
  global: {
    ...initializeGlobalState(),

    setSession: async (data: {
      session: SessionResult["session"];
      user: SessionResult["user"];
    }) => {
      try {
        set((state) => ({
          global: {
            ...state.global,
            session: data.session,
            user: data.user,
            sessionExpiryTime: data.session?.expiresAt
              ? new Date(data.session.expiresAt)
              : state.global.sessionExpiryTime,
          },
        }));
      } catch (error) {
        console.error("Failed to set session:", error);
      }
    },

    setQueryClient: (queryClient: QueryClient) => {
      set((state) => ({
        global: {
          ...state.global,
          queryClient,
        },
      }));
    },

    setSessionExpiryTime: (expiryTime: Date) => {
      set((state) => {
        state.global.sessionExpiryTime = expiryTime;
      });
    },
  },
});
