'use client';

import { immer } from 'zustand/middleware/immer';
import type { StateCreator, StoreMutatorIdentifier } from 'zustand/vanilla';
import { createStore } from 'zustand/vanilla';
import { type GlobalSliceT, createGlobalSlice } from './GlobalSlice';

export type ImmerStateCreator<
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
> = StateCreator<T, [...Mps, ['zustand/immer', never]], Mcs>;

export type SiaStateCreator = ImmerStateCreator<GlobalStoreT>;

// Defines the type of a function used to create a slice of the store. The
// slice has access to all the store's actions and state, but only returns
// the actions and state necessary for the slice.
export type SliceCreator<TSlice extends keyof GlobalStoreT> = (
  ...params: Parameters<SiaStateCreator>
) => Pick<ReturnType<SiaStateCreator>, TSlice>;

export type GlobalStoreT = GlobalSliceT;

export const createGlobalStore = createStore<GlobalStoreT>()(
  immer(
    (...args) =>
      ({
        ...createGlobalSlice(...args),
      }) satisfies GlobalStoreT
  )
);
